import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import { NavLink } from "react-router-dom";
import SectionTestimonials from '../components/section_testimonials';
import SectionFaq from '../components/section_faq';
import SectionContact from '../components/section_contact';

import {Button, TextField} from '@material-ui/core';

import '../styles/blog_body.css'

class About extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            blog: [
                {cover: '/images/deskfix.png', title: 'BLOG', description: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.", author: 'Vaibhav Tayal', link: '/blog1'},
                {cover: '/images/deskfix.png', title: 'BLOG', description: "Lorem ipsum, or lipsum as it ", author: 'Vaibhav Tayal', link: '/blog2'},
                {cover: '/images/deskfix.png', title: 'BLOG', description: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.", author: 'Vaibhav Tayal', link: '/blog3'},
                {cover: '/images/deskfix.png', title: 'BLOG', description: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.", author: 'Vaibhav Tayal', link: '/blog4'},
                {cover: '/images/deskfix.png', title: 'BLOG', description: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.", author: 'Vaibhav Tayal', link: '/blog5'}
            ]
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {

	}

    render(){
        return(
            <div>
                <p style={{fontSize:'xx-large', fontWeight:'bold'}}>Blog</p>
                <div className="body-blog">
                    {
                        this.state.blog.map((blog) => (
                            <div className="blog-cover">
                                <img className="blog-cover-image" src={blog.cover}/>
                                <h3 className="blog-cover-title">{blog.title}</h3>
                                <p className="blog-cover-author">{blog.author}</p>
                                <p className="blog-cover-description">{blog.description}</p>
                                <NavLink activeClassName="active" className="blog-cover-readmore" to={'/blog' + blog.link}>Read More</NavLink>
                            </div>
                        ))
                    }
                </div>

                <SectionContact/>
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(About);
