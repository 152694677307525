import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import SectionTestimonials from '../components/section_testimonials';
import SectionContact from '../components/section_contact';
import ContactForm from '../components/contact-form';

import {Button, TextField, Dialog} from '@material-ui/core';
import {Modal} from 'semantic-ui-react';

import '../styles/home_body.css'

class HomeBody extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
        // window.addEventListener('scroll', (event) => {
        //     if(window.pageYOffset < $('.body-intro-3').position().top - 110 && window.location.pathname != "/home")
        //     {
        //         this.props.history.replace('/home')
        //     }
        //     else if(window.pageYOffset >= $('.body-intro-3').position().top - 110 && window.pageYOffset < $('.body-intro-4').position().top - 100 && window.location.pathname != "/features")
        //     {
        //         this.props.history.replace('/features')
        //     }
        //     else if(window.pageYOffset >= $('.body-intro-4').position().top - 110 && window.pageYOffset < $('.section-contact').position().top - 100 && window.location.pathname != "/partners")
        //     {
        //         this.props.history.replace('/partners')
        //     }
        //     else if(window.pageYOffset >= $('.section-contact').position().top - 110 && window.location.pathname != "/contact")
        //     {
        //         this.props.history.replace('/contact')
        //     }
        // });

        window.addEventListener('load', () => {
            if(window.location.pathname == "/home"){
                var target = $('.body-intro');
            }
            if(window.location.pathname == "/features"){
                var target = $('.body-intro-3');
            }
            else if(window.location.pathname == "/partners"){
                var target = $('.body-intro-4');
            }
            else if(window.location.pathname == "/contact"){
                var target = $('.section-contact');
            }
            if (target && target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 100
                }, 500);
                return false;
            }
        });

        this.props.history.listen((location, action) => {
            if(location.pathname == "/home"){
                var target = $('.body-intro');
            }
            else if(location.pathname == "/features"){
                var target = $('.body-intro-3');
            }
            else if(location.pathname == "/partners"){
                var target = $('.body-intro-4');
            }
            else if(location.pathname == "/contact"){
                var target = $('.section-contact');
            }
            if (target && target.length && action != "REPLACE") {
                $('html,body').animate({
                    scrollTop: target.offset().top - 100
                }, 500);
                return false;
            }
        });
	}

    render(){
        return(
            <div>
                <div className="body-intro">
                    <div className="body-intro-1-1">
                        <div style={{fontSize: "large", width: "60%", textAlign:"left"}}>
                            <p style={{fontSize: "x-large"}}>Procurement Suite<br/><b>for all your office needs.</b></p>
                            <ul style={{lineHeight: "200%"}}>
                                <li>Manage purchase of goods and services.</li>
                                <li>Automated process with realtime monitoring.</li>
                                <li>Smart analytics to reduce expenses.</li>
                            </ul>
                            <Button variant="contained" color="primary" size="medium" onClick={() => this.setState({showContactFormModal: true})}>Schedule A Demo</Button>
                        </div>
                        <img src="images/laptop_ss.png" style={{width: "40%"}}/>
                    </div>
                    <div className="body-intro-1-2">
                        <a href="http://need.deskhub.in" className="body-intro-dsk-references">
                            <img src="images/deskneed.png" className="body-intro-dsk-references-images"/>
                        </a>
                        <a href="http://fix.deskhub.in" className="body-intro-dsk-references">
                            <img src="images/deskfix.png" className="body-intro-dsk-references-images"/>
                        </a>
                        <a href="http://safety.deskhub.in" className="body-intro-dsk-references">
                            <img src="images/desksafety.png" className="body-intro-dsk-references-images"/>
                        </a>
                    </div>
                </div>

                <div className="body-intro-2">
                    <img src="images/software_explainer.png" style={{width: "45%"}}/>
                    <div style={{fontSize: "large", width: "50%", margin: "0 0 5% 5%"}}>
                        <p style={{fontSize: "x-large"}}>Not just a software<br/><b>an assistant for you.</b></p>
                        <p style={{fontSize: 'medium'}}>Deskhub acts as your assistant to help you manage all
                        procurement needs by acting as a bridge between
                        company and supplier / service providers.
                        <br/><br/>
                        Deskhub manages on ground communication with vendors to increase reliability and quality during purchase of goods and services.
                        </p>
                    </div>
                </div>

                <div className="body-intro-3">
                    <p style={{fontSize: "x-large"}}>Features crafted for your needs.</p>
                    <p style={{fontSize: "medium"}}>Specially crafted features to ease your procurement process and give much needed insights about your spends. Optimise your companys expenses from analytics.</p>

                    <div className="body-intro-features">
                        <div style={{width:"30%", alignSelf:"flex-end"}}>
                            <div className="body-intro-feature">
                                <img src="images/feature_vendor_management.png" style={{width: "35%"}}/>
                                <p style={{fontSize:"large", fontWeight:'bold'}}>Vendor Management</p>
                                <p style={{color:"black", fontWeight:"normal"}}>Add, Verify and Approve vendors. Send PO and track orders.</p>
                            </div>
                            <br/><br/>
                            <div className="body-intro-feature">
                                <img src="images/feature_expense_analysis.png" style={{width: "35%"}}/>
                                <p style={{fontSize:"large", fontWeight:'bold'}}>Expense Analysis</p>
                                <p style={{color:"black", fontWeight:"normal"}}>Analyse and reduce expenses with a realtime smart dashboard.</p>
                            </div>
                        </div>
                        <div style={{width:"30%"}}>
                            <div className="body-intro-feature">
                                <img src="images/feature_realtime_monitoring.png" style={{width: "35%"}}/>
                                <p style={{fontSize:"large", fontWeight:'bold'}}>Realtime Updates</p>
                                <p style={{color:"black", fontWeight:"normal"}}>Track orders in realtime and get information about process.</p>
                            </div>
                            <br/><br/>
                            <div className="body-intro-feature">
                                <img src="images/feature_query_management.png" style={{width: "35%"}}/>
                                <p style={{fontSize:"large", fontWeight:'bold'}}>Query Management</p>
                                <p style={{color:"black", fontWeight:"normal"}}>Raise queries and resolve any issues that occur in your service.</p>
                            </div>
                        </div>
                        <div style={{width:"30%", alignSelf:"flex-end"}}>
                            <div className="body-intro-feature">
                                <img src="images/feature_purchase_support.png" style={{width: "35%"}}/>
                                <p style={{fontSize:"large", fontWeight:'bold'}}>Purchase Support</p>
                                <p style={{color:"black", fontWeight:"normal"}}>Get procurement support for your needs. Search, Negotiate and deliver.</p>
                            </div>
                            <br/><br/>
                            <div className="body-intro-feature">
                                <img src="images/feature_service_support.png" style={{width: "35%"}}/>
                                <p style={{fontSize:"large", fontWeight:'bold'}}>Service Support</p>
                                <p style={{color:"black", fontWeight:"normal"}}>Get service support for your needs. Search, Negotiate and deliver.</p>
                            </div>
                        </div>
                    </div>
                    <div className="body-intro-features-mobile">
                        <div className="body-intro-feature">
                            <img src="images/vendor_management.png" style={{width: "35%"}}/>
{
                            // <p style={{fontSize:"large"}}>Vendor Management</p>
                            // <p style={{color:"red", fontWeight:"bold"}}>Learn more</p>
}
                            <p style={{fontSize:"large", fontWeight:'bold'}}>Vendor Management</p>
                            <p style={{color:"black", fontWeight:"normal"}}>Add, Verify and Approve vendors. Send PO and track orders.</p>
                        </div>
                        <div className="body-intro-feature">
                            <img src="images/expense_analysis.jpg" style={{width: "35%"}}/>
                            <p style={{fontSize:"large", fontWeight:'bold'}}>Expense Analysis</p>
                            <p style={{color:"black", fontWeight:"normal"}}>Analyse and reduce expenses with a realtime smart dashboard.</p>
                        </div>
                        <div className="body-intro-feature">
                            <img src="images/realtime_monitoring.png" style={{width: "35%"}}/>
                            <p style={{fontSize:"large", fontWeight:'bold'}}>Realtime Updates</p>
                            <p style={{color:"black", fontWeight:"normal"}}>Track orders in realtime and get information about process.</p>
                        </div>
                        <div className="body-intro-feature">
                            <img src="images/query_management.webp" style={{width: "35%"}}/>
                            <p style={{fontSize:"large", fontWeight:'bold'}}>Query Management</p>
                            <p style={{color:"black", fontWeight:"normal"}}>Raise queries and resolve any issues that occur in your service.</p>
                        </div>
                        <div className="body-intro-feature">
                            <img src="images/purchase_support.jpg" style={{width: "35%"}}/>
                            <p style={{fontSize:"large", fontWeight:'bold'}}>Purchase Support</p>
                            <p style={{color:"black", fontWeight:"normal"}}>Get procurement support for your needs. Search, Negotiate and deliver.</p>
                        </div>
                        <div className="body-intro-feature">
                            <img src="images/service_support.png" style={{width: "35%"}}/>
                            <p style={{fontSize:"large", fontWeight:'bold'}}>Service Support</p>
                            <p style={{color:"black", fontWeight:"normal"}}>Get service support for your needs. Search, Negotiate and deliver.</p>
                        </div>
                    </div>
                </div>

                <div className="body-intro-4">
                    <p style={{fontSize: "x-large", fontWeight:'bold'}}>Fulfillment Partners.</p>
                    <p style={{fontSize: "large"}}>Fulfill Supply and Service needs with our fulfillment partners.</p>

                    <div>
                        <a href="http://deskneed.com" target="blank" className="body-intro-exclusive-partner">
                            <a href="http://deskneed.com" target="blank">
                                <img src="images/deskneed.png" style={{width: "12vw"}} />
                            </a>
                            <br/><br/>
                            <div className="body-intro-exclusive-partner-categories">
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskneed_products/ic_stationery.png" width="30%" />
                                    <p>Stationery</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskneed_products/ic_electronics_&_appliances.png" width="30%" />
                                    <p>IT & Electronics</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskneed_products/ic_office_furniture.png" width="30%" />
                                    <p>Furniture</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskneed_products/ic_safety_equipments.png" width="30%" />
                                    <p>Safety Item</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskneed_products/ic_housekeeping_&_canteen.png" width="30%" />
                                    <p>Grocery</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskneed_products/ic_corporate_gifting.png" width="30%" />
                                    <p>Corporate Gifting</p>
                                </div>
                            </div>
                        </a>
                        <a href="http://deskfix.in" target="blank" className="body-intro-exclusive-partner">
                            <a href="http://deskfix.in" target="blank">
                                <img src="images/deskfix.png" style={{width: "12vw"}} />
                            </a>
                            <br/><br/>
                            <div className="body-intro-exclusive-partner-categories">
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskfix_services/ic_electrician.png" width="30%" />
                                    <p>Electrician</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskfix_services/ic_plumbing.png" width="30%" />
                                    <p>Plumber</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskfix_services/ic_ac_maintenance.png" width="30%" />
                                    <p>AC Maintenance</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskfix_services/ic_lan_&_telecom.png" width="30%" />
                                    <p>Lan, Telecom & CCTV</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskfix_services/ic_carpentry.png" width="30%" />
                                    <p>Carpenter</p>
                                </div>
                                <div style={{width: "50%", flexGrow: 1, marginBottom: "4%"}}>
                                    <img src="images/deskfix_services/ic_it_repair.png" width="30%" />
                                    <p>IT Repair</p>
                                </div>
                            </div>
                        </a>
                    </div>
{
                    // <div style={{width: 50%; flex-grow: 1; marginBottom: 2.5%;">
                    //     <img src="images/deskfix_services/ic_printing_(sign_boards,_offset).svg" width="40%" />
                    //     <p>Printing</p>
                    // </div>
                    // <div style={{width: 50%; flex-grow: 1; marginBottom: 2.5%;">
                    //     <img src="images/deskfix_services/ic_painter.svg" width="40%" />
                    //     <p>Painting</p>
                    // </div>
                    // <div style={{width: 50%; flex-grow: 1; marginBottom: 2.5%;">
                    //     <img src="images/deskfix_services/ic_pest_control.svg" width="8%" />
                    //     <p>Pest Control</p>
                    // </div>
                    // <div style={{width: 50%; flex-grow: 1; marginBottom: 2.5%;">
                    //     <img src="images/deskfix_services/ic_fire_&_safety.svg" width="8%" />
                    //     <p>Fire & Safety</p>
                    // </div>
}
                </div>

                <div className="traction-container">
                    <div>
                        <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>1500+</p>
                        <p style={{fontSize: "large"}}>Orders Served</p>
                    </div>
                    <div>
                        <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>800</p>
                        <p style={{fontSize: "large"}}>B2B Customers</p>
                    </div>
                    <div>
                        <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>50+</p>
                        <p style={{fontSize: "large"}}>Cities Covered</p>
                    </div>
                </div>

                <Dialog open={this.state.showContactFormModal}
                onClose={() => this.setState({showContactFormModal: false})}>
                    <ContactForm header="Schedule a Demo!" query_category="Schedule Demo" category_disabled="true"/>
                </Dialog>
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
const mapDispatchToProps = dispatch => ({
    // setAppData: (data) => dispatch(appDataAction(data)),
    // newDataRowAction: (data) => dispatch(newDataRowAction(data)),
})

export default withRouter(HomeBody);
