import $ from 'jquery';
import createHistory from 'history/createBrowserHistory';
import SmartHitter from './smart-hitter';

const serverAddress = '/api';
// const serverAddress = 'http://localhost/doorhopperServer/public/api';
// const serverAddress = 'https://deskneed.com/api';
//science 2 home
// const serverAddress = 'http://192.168.0.06/deskneed/public/api';
// ISHU's
// const serverAddress = 'http://192.168.0.15/doorhopperServer/public/api';

// const serverAddress = 'http://10.10.99.196';
// const serverAddress = 'http://192.168.0.2';
// const serverAddress = 'http://192.168.43.171';

var sh = new SmartHitter();

let monitor_city = localStorage.getItem('city');
const Client={
    createContactRequest(token=undefined,reg_id=undefined,username=undefined,new_data_row,form_body,networkReqNAME=""){
        let data = `reg_id=${reg_id}&token=${token}&username=${username}&new_data_row=${JSON.stringify(new_data_row)}`
        return fetch(`${serverAddress}/corp/contact/general?`+data, {
            method: 'POST',
            body: form_body||'',
        }).then(res=>res.json())
        // return sh.hit('/api/vdhp/contact/general/'+networkReqNAME, `${serverAddress}/corp/contact/general`, 'POST', data)
    }
}
export default Client;
