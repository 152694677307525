import React from 'react';
import { connect } from 'react-redux';
import Client from '../services/Client';

import Button from '@material-ui/core/Button';

import '../styles/footer.css'

class Footer extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
	}

    render(){
        return(
            <div className="footer">
                <div className="footer-partners">
                    <a href="http://deskneed.com" target="blank" className="footer-dsk-references">
                        <img src="images/deskneed.png" className="footer-dsk-references-images"/>
                    </a>
                    <a href="http://deskfix.in" target="blank" className="footer-dsk-references">
                        <img src="images/deskfix.png" className="footer-dsk-references-images"/>
                    </a>
                </div>

                <div className="footer-links">
                    <div className="first">
                        <span className="footer-headings">Contact Us</span> <br/>
                        <img src="/images/icon_phone.png"/>+91-9954683549<br/>
                        <img src="/images/icon_location.png"/>Assam, India<br/>
                        <img src="/images/icon_mail.png"/>contact@deskneed.com<br/>
                    </div>
                    <div className="second">
                        <span className="footer-headings">Our Company</span><br/>
                        About <br/>
                        Features <br/>
                        Partners<br/>
                        Blog
                    </div>
                    <div className="third" >
                        <span className="footer-headings">Social Contacts</span><br/>
                        <img src="/images/facebook.svg"/>Facebook<br/>
                        <img src="/images/linkedin.svg"/>LinkedIn<br/>
                        <img src="/images/twitter.png"/>Twitter<br/>
                        <img src="/images/instagram.svg"/>Instagram<br/>
                        <img src="/images/angel.png"/>Angel<br/>
                    </div>
                    <div className="third" >
                        <span className="footer-headings">Reach us</span><br/>
                        <a href="https://goo.gl/maps/ohQHnkQRdD9BRSdz5" target="blank" style={{color:'black', textDecoration:'none'}}>
                        <img src="/images/icon_location.png"/>R G B Road, Ganeshguri (Near Assam Minorities Development Board), Guwahati, Assam - 781006<br/>
                        +91-9954683549</a>
                    </div>
                </div>
            </div>
		)
    }
}
export default Footer;
