import React from 'react';
import { connect } from 'react-redux';
import Client from '../services/Client';

import {Button, TextField} from '@material-ui/core';

import '../styles/section_faq.css'

class SectionFaq extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
	}

    render(){
        return(
            <div className="section-faq">
                <p style={{fontWeight: "bold", fontSize: "x-large"}}>Frequently Asked Questions</p>
                <div>
                    <div className="faq-qa">
                        <p className="faq-question">What is Deskhub?</p>
                        <p className="faq-answer">
                            Deskhub is a software solution for purchase and service procurement of companies for their offices, depots, factories and other business places.
                        </p>
                    </div>
                    <div className="faq-qa">
                        <p className="faq-question">Does Deskhub provide stationery?</p>
                        <p className="faq-answer">
                            Deskhub lets you add vendors and order from them. Deskhub has its parner supplier Deskneed which facilitates all kinds of office supplies like stationeries, office furniture, appliances and more.
                        </p>
                    </div>
                </div>
                <div>
                <div className="faq-qa">
                    <p className="faq-question">Does Deskhub provide services like electrician?</p>
                    <p className="faq-answer">
                        Deskhub lets you add vendors and order services from them. Deskhub has its parner service provider Deskfix which facilitates all kinds of office service needs like electrician, plumber, carpenter, AC repair, cleaning and more.
                    </p>
                </div>
                <div className="faq-qa">
                    <p className="faq-question">Can a company add its own vendors in Deskhub?</p>
                    <p className="faq-answer">
                        YES, Deskhub lets you manage all procurement process with ease. Adding and Verifying order, Generating Purchase Order, Order Fulfillment, Invoicing and Payment tracking and more features shall be included as per compnay needs.
                    </p>
                </div>
                </div>
            </div>
		)
    }
}
export default SectionFaq;
