import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Client from '../services/Client';
import SectionTestimonials from '../components/section_testimonials';
import SectionFaq from '../components/section_faq';
import SectionContact from '../components/section_contact';

import {Button, TextField} from '@material-ui/core';

import '../styles/about_body.css'

class About extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {

	}

    render(){
        return(
            <div>
                <p style={{fontSize:'xx-large', fontWeight:'bold'}}>About Us</p>
                <div className="body-about">
                    <div className="body-about-1-1">
                        <div style={{fontSize: "large", width: "60%", textAlign:"left", paddingRight:'5%'}}>
                            <p style={{fontSize: "x-large"}}><b>Admin Enterprise Solution Provider.</b></p>
                            <p style={{fontSize: 'medium'}}>Deskhub acts as your assistant to help you manage all
                                procurement needs by acting as a bridge between
                                company and supplier / service providers.
                                <br/><br/>
                                Deskhub is a SaaS based Admin Enterprise solution provider.
                                Enterprises can take care of all admin related works like: goods-procurement management,
                                vendor management and facility management services.
                                This platform (software) will help you receive requirements from employees,
                                supervisors or different departments and share/pass it to respective vendors.
                                Hence,the entire loop of work execution(from receiving orders to delivering the
                                same to client/customer) can be maintained in a single platform.
                            </p>
                        </div>
                        <img src="images/wow_technology.png" style={{width: "40%"}}/>
                    </div>
                </div>

                <div className="body-about-2">
                    <div className="body-about-2-1">
                        <div style={{fontSize: "large", width: "60%", textAlign:"left"}}>
                            <p style={{fontSize: "x-large"}}>Procurement Suite<br/><b>for all your office needs.</b></p>
                            <ul style={{lineHeight: "200%"}}>
                                <li>Procurement Management System</li>
                                <li>Facility Management  system</li>
                                <li>Create order online with realtime monitoring. </li>
                                <li>Vendor Management - Add and manage vendors.</li>
                                <li>Contract / Agreement Management </li>
                                <li>Query management regarding any orders.</li>
                                <li>View & Download invoices, quotations, and challans easily.</li>
                                <li>Admin solution for multiple properties from one integrated system. </li>
                                <li>Payment tracking, GST report</li>
                            </ul>
                            <Button variant="contained" color="primary" size="medium">Know More</Button>
                        </div>
                        <img src="images/laptop_ss.png" style={{width: "40%"}}/>
                    </div>
                </div>

                <div className="body-about-3">
                    <img src="images/software_explainer.png" style={{width: "45%"}}/>
                    <div style={{fontSize: "large", width: "50%", margin: "0 0 5% 5%"}}>
                        <p style={{fontSize: "x-large"}}>Not just a software<br/><b>an assistant for you.</b></p>
                        <p style={{fontSize: 'medium'}}>Deskhub acts as your assistant to help you manage all
                        procurement needs by acting as a bridge between
                        company and supplier / service providers.
                        <br/><br/>
                        Deskhub manages on ground communication with vendors to increase reliability and quality during purchase of goods and services.
                        </p>
                    </div>
                </div>

                <div className="body-about-traction">
                    <div>
                        <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>1500+</p>
                        <p style={{fontSize: "large"}}>Orders Served</p>
                    </div>
                    <div>
                        <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>800</p>
                        <p style={{fontSize: "large"}}>B2B Customers</p>
                    </div>
                    <div>
                        <p style={{fontSize: "xxx-large", fontWeight: "bold", margin:"10px 0 0 20px"}}>50+</p>
                        <p style={{fontSize: "large"}}>Cities Covered</p>
                    </div>
                </div>

                <SectionTestimonials/>

                <SectionFaq/>

                <SectionContact/>
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(About);
