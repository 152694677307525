import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import PageContainer from './routes/page_container'

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Route path='/' component={PageContainer} />
        </BrowserRouter>
    </div>
  );
}

export default App;
