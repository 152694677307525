import React from 'react';
import { connect } from 'react-redux';
import Client from '../services/Client';
import { NavLink } from "react-router-dom";

import {Button, Icon} from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';

import '../styles/topbar.css'

class Topbar extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
	}

    render(){
        return(
            <div className="topbar-main">
                <div className="sidebar-trigger">
                    <DehazeIcon onClick={() => this.props.toggleSidebar()}/>
                </div>
                <div className="topbar-logo">
                    <a href="/">
                        <img alt="logo" className="brand-image" src="images/deskhub.png"/>
                    </a>
                </div>
                <div className="topbar-navigation">
                    <NavLink activeClassName="active" className="nav-link" to="/home">Home</NavLink>
                    <NavLink activeClassName="active" className="nav-link" to="/features">Features</NavLink>
                    <NavLink activeClassName="active" className="nav-link" to="/partners">Partners</NavLink>
                    <NavLink activeClassName="active" className="nav-link" to="/contact">Contact</NavLink>
{
                    // <NavLink activeClassName="active" className="nav-link" to="/blog">Blog</NavLink>
}
                </div>
                <div className="topbar-buttons">
                    <Button variant="contained" color="primary" size="small">Subscribe</Button>
                </div>
            </div>
		)
    }
}
export default Topbar;
