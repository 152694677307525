import React from 'react';
import { connect } from 'react-redux';
import Client from '../services/Client';
import { NavLink } from "react-router-dom";

import {Button, Icon} from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';

import '../styles/sidebar.css'

class Sidebar extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
	}

    render(){
        return(
            <div className="sidebar-main">
                <div onClick={() => this.props.toggleSidebar()}>
                    <span class="material-icons sidebar-close">
                        keyboard_backspace
                    </span>
                </div>
                <div className="sidebar-navigation">
                    <NavLink activeClassName="active" className="sidebar-nav-link" to="/home" onClick={() => this.props.toggleSidebar()}>Home</NavLink>
                    <NavLink activeClassName="active" className="sidebar-nav-link" to="/features" onClick={() => this.props.toggleSidebar()}>Features</NavLink>
                    <NavLink activeClassName="active" className="sidebar-nav-link" to="/partners" onClick={() => this.props.toggleSidebar()}>Partners</NavLink>
                    <NavLink activeClassName="active" className="sidebar-nav-link" to="/contact" onClick={() => this.props.toggleSidebar()}>Contact</NavLink>
                    {
                    // <NavLink activeClassName="active" className="sidebar-nav-link" to="/blog" onClick={() => this.props.toggleSidebar()}>Blog</NavLink>
                }
                </div>
                <div className="sidebar-buttons">
                    <Button variant="contained" color="primary" size="medium">Become Partner</Button>
                </div>
                <div className="sidebar-partners">
                    <a href="http://deskneed.com" target="blank" className="sidebar-dsk-references">
                        <img src="images/deskneed.png" className="sidebar-dsk-references-images"/>
                    </a>
                    <a href="http://deskfix.in" target="blank" className="sidebar-dsk-references">
                        <img src="images/deskfix.png" className="sidebar-dsk-references-images"/>
                    </a>
                </div>
                <div className="sidebar-contact">
                    <span className="sidebar-contact-headings">Contact Us</span> <br/>
                    <img src="images/icon_phone.png"/>+91-9954683549<br/>
                    <img src="images/icon_location.png"/>Assam, India<br/>
                    <img src="images/icon_mail.png"/>contact@deskneed.com<br/>
                </div>
            </div>
		)
    }
}
export default Sidebar;
