import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import HomeBody from '../components/home_body'
import SectionContact from '../components/section_contact'
import SectionTestimonials from '../components/section_testimonials'

class Home extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
        window.onscroll = function() {
            if(window.pageYOffset === 0) {
                this.setState({
                    fixedHeader: false
                })
            }
            else{
                this.setState({
                    fixedHeader: true
                })
            }
        }.bind(this);
	}

    render(){
        return(
            <div>

                <HomeBody history={this.props.history}/>

                <SectionTestimonials/>

                <SectionContact/>

            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(Home);
