import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Sidebar from '../components/sidebar'
import Topbar from '../components/topbar'
import Home from './home'
import About from './about'
import Blog from './blog'
import Footer from '../components/footer'

class PageContainer extends React.Component{
    constructor(props){
        super(props)
        this.state ={
        }
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
    }
	componentDidMount() {
        window.onscroll = function() {
            if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
                $(".topbar-main").css('height', '10vh');
            } else {
                $(".topbar-main").css('height', '15vh');
            }
        }
  	}

    toggleSidebar(){
        var left = $(".sidebar-main").css('left');
        left = left.substring(0, left.length - 2)
        if(left >= -10){
            $(".sidebar-main").css('left', '-75vw');
        }
        else{
            $(".sidebar-main").css('left', '0vw');
        }
    }

    render(){
        return(
            <div>
                <Sidebar history={this.props.history} toggleSidebar={this.toggleSidebar}/>
                <Topbar history={this.props.history} toggleSidebar={this.toggleSidebar}/>
                <div style={{height:'15vh'}}/>
                <Switch style={{width: "100%", marginTop:'15vh'}} onChange={()=>{alert("lol")}}>
                    <Route
                        path="/home"
                        render={(props)=>(<Home history={props.history}/>)}
                    />
                    <Route
                        path="/about"
                        render={(props)=>(<About history={props.history}/>)}
                    />
                    <Route
                        path="/blog"
                        render={(props)=>(<Blog history={props.history}/>)}
                    />
                    <Redirect exact from='/' to='/home'/>
                    <Route
                        path="/"
                        render={(props)=>(<Home history={props.history}/>)}
                    />
                </Switch>
                <Footer/>
            </div>
		)
    }
}
const mapStateToProps = state => ({
    ...state
})
export default withRouter(PageContainer);
